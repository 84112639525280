<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useAppStore } from '@/stores/app.store'

  const appStore = useAppStore()
  const { cmsData } = storeToRefs(appStore)
</script>

<template>
  <div>
    <slot
      v-if="cmsData?.content"
      name="navigation-header"
      :hide-breadcrumb="true"
      :is-section-page="true"
    />

    <NuxtPage
      v-if="cmsData?.content"
      :content="cmsData.content"
    />
    <slot name="footer" />
  </div>
</template>
